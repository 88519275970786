<template>
  <div id="main">
    <el-main>
      <bread-crumb :type="2" :step="1"></bread-crumb>
      <div class="stepBox">
        <comm-step :active="1" :percentage="25" :type="2"></comm-step>
        <apply-identity></apply-identity>
      </div>
    </el-main>
  </div>
</template>

<script>
import commStep from "c/commStep";
import applyIdentity from "c/index/register/applyIdentity";
import breadCrumb from "c/breadcrumb";

export default {
  data() {
    return {
      imageUrl1: "",
      imageUrl2: "",
      percentage: 50,
    };
  },
  components: {
    commStep,
    breadCrumb,
    applyIdentity
  },
  created(){
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  min-height: 80%;
  overflow: auto;
  .el-main {
    max-width: 1200px;
    min-width: 1024px;
    margin: 6px auto;
    padding: 0 50px;
    overflow: hidden;

  }
  .btnBox {
    width: 100%;
    padding: 20px 0;
  }
  .stepBox {
    background: #fff;
    margin-top: 10px;
    padding: 60px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
}
</style>
